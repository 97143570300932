<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close" @open="getData" :close-on-click-modal="false">
        <div style="display: flex;height: 50vw;overflow: auto;">
            <div style="width: 160px">
                <div v-for="v, i in reports" :key="i" style="border:1px solid #ccc;padding:10px;margin: 5px 0;cursor: pointer;"
                    @click="getReportByID(v.id)">
                    <div>{{ v.orgName }}</div>
                    <div>{{ v.reportTime }}</div>
                </div>
            </div>
            <div style="flex: 1;padding: 10px 20px;white-space: pre-line;">
                <h3>检查结果</h3>
                <div>
                    {{ report.jlb ? report.jlb.JCJG : '' }}
                </div>
                <h3>终检结论</h3>
                <div>
                    {{ report.jlb ? report.jlb.ZJJL : '' }}
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
            loading: false,
            reports: [],
            report: {
                jlb: {}
            }
        }
    },
    methods: {
        async handleDelete() {

        },
        async getReportByID(ID) {
            try {
                const resp = await this.$http.get(`/phr/report/view/${ID}`)
                if (resp.data.status == 'success') {
                    this.report = resp.data.data
                } else {
                    this.$alert(resp.data.msg, '出错了', { type: 'error' })
                }
            } catch (error) {
                this.$alert(error.message, '出错了', { type: 'error' })
            }
        },
        async getData() {
            try {
                const resp = await this.$http.get('/industrial/report', { params: { SFZH: this.form.SFZH } })
                if (resp.data.status == 'success') {
                    this.$message[resp.data.status](resp.data.msg)
                    this.reports = resp.data.data
                    if (this.reports.length > 0) {
                        this.getReportByID(this.reports[0].id)
                    }
                } else {
                    this.$alert(resp.data.msg, '出错了', { type: 'error' })
                }
            } catch (error) {
                this.$alert(error.message, '出错了', { type: 'error' })
            }
        },
        close() {
            this.$emit("close")
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
